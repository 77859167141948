import { dateFormats } from '@mindfulchefuk/lib/dateFormats'
import { addDays } from 'date-fns'

interface GetNextDateParams {
  fromDate: string
  unavailableDates: string[]
}

export default function getNextDate({
  fromDate,
  unavailableDates,
}: GetNextDateParams): string {
  let currentDate: string | Date = fromDate
  let nextDate
  while (!nextDate) {
    const formattedDate = dateFormats.iso(currentDate)
    const isAvailable = !unavailableDates.includes(formattedDate)

    if (isAvailable) {
      nextDate = formattedDate
    } else {
      currentDate = addDays(currentDate, 1)
    }
  }

  return nextDate
}
